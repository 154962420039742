<template>
  <div class="bg">
    <div class="top mb8">
      <h3>个人信息</h3>
      <p><span>姓名</span>张海峰</p>
      <p><span>手机号</span>15966668888</p>
      <p><span>身份证号</span>15966668888</p>
      <p><span>年龄</span>15966668888</p>
      <p><span>性别</span>15966668888</p>
      <img src="../../assets/img/help_0.png" alt="" class="status" v-show="status == 0">
      <img src="../../assets/img/help_1.png" alt="" class="status" v-show="status == 1">
      <img src="../../assets/img/help_2.png" alt="" class="status" v-show="status == 2">

      
    </div>
    <div class="top mb8">
      <h3>身份信息</h3>
      <van-grid :gutter="10">
        <van-grid-item
          v-for="value in 2"
          :key="value"
          icon="photo-o"
          @click="showImagePreview"
        >
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg" />
        </van-grid-item>
      </van-grid>
    </div>
    <div class="top mb8">
      <h3>求助标题</h3>
      <p>
        我还想在重返校园，
      </p>
    </div>

    <div class="top mb8">
      <h3>筹款金额</h3>
      <p class="">589，500.00元</p>
    </div>
        <div class="top mb8">
      <h3>求助信息</h3>
   <p>
        我还想在重返校园，叔叔阿姨大家我还想在重返校园，叔叔阿姨大家我还想在重返校园，叔叔阿姨大家我还想在重返校园，叔叔阿姨大家我还想在重返校园，叔叔阿姨大家我还想在重返校园，叔叔阿姨大家...
      </p>
    </div>
    <div class="top mb8">
      <h3>疾病病历</h3>
      <van-grid :gutter="10">
        <van-grid-item
          v-for="value in 8"
          :key="value"
          icon="photo-o"
          @click="showImagePreview"
        >
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg" />
        </van-grid-item>
      </van-grid>
    </div>
        <div class="top mb8">
      <h3>贫困证明</h3>
      <van-grid :gutter="10">
        <van-grid-item
          v-for="value in 8"
          :key="value"
          icon="photo-o"
          @click="showImagePreview"
        >
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg" />
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      status:0
    };
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  methods: {
    showImagePreview() {
      ImagePreview({
        images: [
          "https://img.yzcdn.cn/vant/apple-1.jpg",
          "https://img.yzcdn.cn/vant/apple-2.jpg",
        ],
        startPosition: 1,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  h3 {
    text-align: left;
    color: rgba(45, 45, 45, 1);
    margin-bottom: 16px;
  }
  .top {
    color: rgba(68, 68, 68, 1);
    padding: 16px;
    background: #fff;
    text-align: left;
    position: relative;
    .status{
      position: absolute;
      top: 16px;
      right: 16px;
      width: 60px;
      height: 60px;
    }
    p {
      margin-bottom: 8px;
    }
    span {
      display: inline-block;
      width: 56px;
      margin-right: 16px;
    }
  }
}
</style>
